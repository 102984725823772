<template>
  <div class="news">
    <div class="news__wrapper">
      <div class="news__header-background">
        <!-- :style="{
          backgroundImage: `url(${lastNew.header_image})`,
        }" -->
      </div>
      <div class="news__main-container">
        <!-- <div class="news__details-box">
          <h2 class="news__details-box_title">
            {{ lastNew.title }}
          </h2>
          <span class="news__details-box_data"
            >{{ lastNew.date_published }} | by {{ lastNew.author }}</span
          >

          <BaseButton
            class="button button--xs button--white"
            text="READ ARTICLE"
            @click="$router.push({ path: `/media/in-the-news/${lastNew.slug}` })"
          />
        </div> -->

        <div class="news__title-box" ref="titleBox">
          <h2 class="news__title-box_title">In The News</h2>
          <div class="news__title-box_right">
            <div class="news__title-box_right-content">
              <Tag
                class="tag--public"
                :class="{ 'tag--selected': item.selected }"
                v-for="(item, index) in getVisibleTags"
                :key="`tag-${index}`"
                :value="item.title"
                @click="changeFilterValue(item)"
              />
              <AdditionalInfo
                v-if="getHiddenTags.length"
                class="additional-tags--public"
                :items="getHiddenTags"
                :parentClientRect="tagsClientRect"
                :isPublic="true"
                @getParentClientRect="getParentClientRect"
                @changeFilterValue="changeFilterValue"
              ></AdditionalInfo>
            </div>
            <div class="divider divider--vertical"></div>
            <div class="news__title-box_right-content">
              <PublicSelect
                :items="sortData"
                :selected="cardData.sort"
                @input="sortNews($event.id)"
              ></PublicSelect>
            </div>
          </div>
        </div>

        <div v-if="newsList.length > 0" class="news__news-box">
          <router-link
            v-for="(item, key) in newsList"
            :key="`${key}-item`"
            :to="{ path: `/media/in-the-news/${item.slug}` }"
            v-slot="{ navigate }"
            custom
          >
            <div class="news__news-box_card" @click="navigate">
              <div
                class="image"
                :style="{
                  backgroundImage: `url(${item.search_image}`,
                }"
                @click="$router.push({ path: `/media/in-the-news/${item.slug}` })"
              >
                <div class="image__preview"><p>Click to View</p></div>
              </div>
              <div class="type">In The News</div>
              <h3 class="title">{{ item.title }}</h3>
              <span class="data">{{ item.date_published }} | by {{ item.author }}</span>
            </div>
          </router-link>
        </div>
        <h6 class="news__empty" v-else>No news</h6>

        <div v-if="showLoadMoreButton && hasMorePages" class="news__button-box">
          <BaseButton
            class="button button--xs button--white"
            text="LOAD MORE"
            @click.native="handleLoadMoreClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/buttons/BaseButton.vue";
import PublicSelect from "@/components/inputs/PublicSelect.vue";
import Tag from "@/components/Tag.vue";
import AdditionalInfo from "@/components/AdditionalInfo.vue";

export default {
  components: { BaseButton, PublicSelect, Tag, AdditionalInfo },

  data() {
    return {
      tagsClientRect: { bottom: 0, right: 0 },
      sortData: [
        { id: "newest_to_oldest", title: "Date Added (newest to oldest)" },
        { id: "oldest_to_newest", title: "Date Added (oldest to newest)" },
        { id: "title_a_z", title: "Title (A-Z)" },
        { id: "title_z_a", title: "Title (Z-A)" },
        { id: "author_a_z", title: "Author (A-Z)" },
        { id: "author_z_a", title: "Author (Z-A)" },
      ],
      filterBlock: {
        list: "year_news",
        type: "collection",
      },
      cardData: {
        latest_id: 0,
        sort: "newest_to_oldest",
        tags: "",
        year: "",
        page: 1,
      },
      showLoadMoreButton: true,
      loading: false,
    };
  },
  head: {
    title: function () {
      return {
        inner: "In The News browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: "In The News browngrotta arts",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "In The News browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "In The News browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "In The News browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: "Description In The News browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "In The News browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: "Description In The News browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "In The News browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  async created() {
    //await this.apiGetLastNew();
    await this.apiGetFiltersBlock(this.filterBlock);
    await this.apiGetNewsList({ sort: this.cardData.sort, page: 1 }); // latest_id: this.lastNew.id,

    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Media",
        to: "/media",
        clickable: true,
      },
      {
        title: "In the news",
        to: "/media/in-the-news",
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },

  watch: {
    tagsData: {
      handler: function (val, oldVal) {
        let year = "";
        val.forEach(el => {
          if (el.selected) {
            year = el.key === "" ? "" : el.key;
          }
        });
        this.cardData.year = year;
        this.selectTypes(year);
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("publicapi/news", {
      lastNew: "getLastNew",
      hasNews: "getHasNews",
      newsList: "getNewsList",
      hasMorePages: "getHasMorePages",
      tagsData: "getFilters",
    }),

    getVisibleTags() {
      return this.tagsData.slice(0, 5);
    },

    getHiddenTags() {
      return this.tagsData.slice(5, this.tagsData.length);
    },
  },

  methods: {
    ...mapActions("publicapi/news", ["apiGetLastNew", "apiGetNewsList", "apiGetFiltersBlock"]),
    ...mapMutations("publicapi/news", ["changeFilterValue"]),
    ...mapMutations(["setBreadcrumbs"]),

    getParentClientRect() {
      this.tagsClientRect = this.$refs.titleBox.getBoundingClientRect();
    },

    async sortNews(sort) {
      let data = { ...this.cardData, latest_id: this.lastNew.id, sort, page: 1 };
      let success = await this.apiGetNewsList(data);
      if (success) {
        this.cardData = { ...data };
      }
    },

    async selectTypes(types) {
      let data = { ...this.cardData, latest_id: this.lastNew.id, page: 1, year: types };
      let success = await this.apiGetNewsList(data);
      if (success) {
        this.cardData = data;
      }
    },

    loadMore() {
      if (this.loading || !this.hasMorePages) return;
      this.loading = true;

      this.cardData.page += 1;
      this.apiGetNewsList({
        latest_id: this.lastNew.id,
        sort: this.cardData.sort,
        page: this.cardData.page,
      });
      this.loading = false;
    },
    handleLoadMoreClick() {
      this.showLoadMoreButton = false;
      this.loadMore();
      this.enableInfiniteScroll();
    },

    enableInfiniteScroll() {
      window.addEventListener("scroll", this.handleScroll);
    },

    handleScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      const bottomPosition = document.documentElement.scrollHeight;

      if (scrollPosition >= bottomPosition - 100) {
        this.loadMore();
      }
    },
  },
  mounted() {
    if (!this.showLoadMoreButton) {
      this.enableInfiniteScroll();
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
  font-size: 18px;
  line-height: 1.3;
  @media screen and (max-width: $xs) {
    font-size: 16px;
    line-height: 1.5;
  }
}

.news {
  display: flex;

  &__header {
    &-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 58.4rem;
      z-index: 0;
      background-image: url(../../../../assets/images/news__image.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      @media screen and (max-width: $xs) {
        height: 33.5rem;
      }

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($color: $lightBlack, $alpha: 0.45);
        z-index: 1;
      }
      &::after {
        content: "";
        bottom: -1px;
        position: absolute;
        width: 100%;
        height: 50%;
        background: linear-gradient(
          0deg,
          rgba($color: $lightBlack, $alpha: 1) 0%,
          rgba($color: $lightBlack, $alpha: 0) 100%
        );
        z-index: 1;
      }
    }
  }

  &__wrapper {
    position: relative;
    min-height: 100vh;
    min-width: 100vw;
  }

  &__main-container {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 57.3rem 10.5rem 30rem;
    z-index: 2;
    @media screen and (max-width: $xs) {
      padding: 49.9rem 2.6rem 30rem;
    }
  }

  &__details-box {
    width: 70%;
    @media screen and (max-width: $xs) {
      width: 100%;
    }

    &_title {
      font-size: 40px;
      line-height: 1.25;
      margin-bottom: 1rem;
      @media screen and (max-width: $xs) {
        font-size: 35px;
        margin-bottom: 50px;
      }
    }

    &_data {
      font-size: 16px;
      line-height: 1.5rem;
      text-transform: uppercase;
      @media screen and (max-width: $xs) {
        font-size: 14px;
      }
    }

    .button {
      margin-top: 30px;
    }
  }

  &__title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 20.2rem 0rem 6.5rem;
    @media screen and (max-width: $xs) {
      margin: 11rem 0rem 4rem;
    }
    &_right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media screen and (max-width: $md) {
        width: 100%;
        flex-direction: column;
        .divider {
          display: none;
        }
        &-content {
          &:last-of-type {
            margin-left: 10px;
          }
        }
      }
      &-content {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        // & > * {
        //   margin-bottom: 10px;
        // }
      }
      @media screen and (max-width: $lg) {
        margin-top: 30px;
        margin-left: -10px;
      }
      @media screen and (max-width: $xs) {
        &-content {
          flex-wrap: wrap;
        }
      }
    }
    &_title {
      font-size: 40px;
      line-height: 1.25;
      @media screen and (max-width: $xs) {
        font-size: 35px;
        margin-bottom: 30px;
      }
    }
  }
  &__empty {
    font-size: 20px;
    font-weight: 600;
    padding: 50px 10px;
    text-align: center;
    text-transform: uppercase;
  }

  &__news-box {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, calc(33% - 2rem));
    grid-column-gap: 4rem;
    grid-row-gap: 9rem;
    @media screen and (max-width: $md) {
      grid-template-columns: repeat(2, calc(50% - 2rem));
    }
    @media screen and (max-width: $xs) {
      grid-template-columns: 100%;
      grid-column-gap: 0rem;
      grid-row-gap: 9rem;
    }

    &_card {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      .image {
        position: relative;
        height: 27vw;
        width: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 4px;
        @media screen and (max-width: $xs) {
          height: 90vw;
        }

        &__preview {
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-self: center;
          opacity: 0;
          border-radius: 4px;
          transition: all 0.3s ease;

          p {
            width: 100%;
            text-align: center;
            font-size: 3rem;
            line-height: 1.67;
            letter-spacing: -1px;
          }
        }

        &:hover {
          .image__preview {
            opacity: 1;
            background-color: rgba($color: $blue, $alpha: 0.83);
          }
        }
      }

      .type {
        display: flex;
        justify-content: center;
        align-content: center;
        text-transform: uppercase;
        border: 1px solid $white;
        border-radius: 4px;
        width: max-content;
        min-width: 9.7rem;
        margin-top: 26px;
        font-size: 14px;
        line-height: 1.8;
        padding: 0.1rem 1rem;
      }

      .title {
        font-size: 24px;
        line-height: 1.3;
        margin-top: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
        // &:hover {
        //   text-decoration: underline;
        // }
      }

      .data {
        margin-top: 12px;
        text-transform: uppercase;
        @media screen and (max-width: $xs) {
          margin-top: 10px;
          font-size: 14px;
          line-height: 1.8rem;
        }
      }
    }
  }

  &__button-box {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    margin-top: 8.6rem;

    .button {
      min-width: 180px;
    }
  }
}
</style>

